const FileNameSpace = 'file'

const FileActionTypes = Object.freeze({
  LoadFile: 'LoadFile',
  LoadFiles: 'LoadFiles',
})

const FileMutationTypes = Object.freeze({
  SetFile: 'SetFile',
  SetFiles: 'SetFiles',
})

const FileGetterTypes = Object.freeze({})

export { FileNameSpace, FileActionTypes, FileMutationTypes, FileGetterTypes }
