import { AnalyticMutationTypes } from './types'

export const mutations = {
  [AnalyticMutationTypes.SetAnalyticCostDiagram](state, { diagram }) {
    state.costDiagram = diagram
  },
  [AnalyticMutationTypes.SetAnalyticDeliveryDiagram](state, { diagram }) {
    state.deliveryDiagram = diagram
  },
  [AnalyticMutationTypes.SetOrderDataSourceGraph](state, { graph }) {
    state.graph = graph
  },
}
