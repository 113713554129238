import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    verticalMenuCollapseActivator: $themeConfig.layout.menu.collapseActivator,
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    UPDATE_VERTICAL_MENU_COLLAPSE_ACTIVATOR(state, val) {
      state.verticalMenuCollapseActivator = val
    },
  },
}
