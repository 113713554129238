import { RoleActionTypes, RoleMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [RoleActionTypes.LoadRoleCollection]({ commit }) {
    try {
      const response = await this.$api.get_role_collection.fetch()

      if (response.data) {
        commit(RoleMutationTypes.SetRoleCollection, {
          roles: response.data.content,
          pageCount: response.data.pageCount,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RoleActionTypes.LoadRole]({ state, commit }, { id }) {
    const { roles } = state
    // TODO replace getter
    const role = roles.find(({ $id }) => $id === id)

    if (role) {
      commit(RoleMutationTypes.SetRole, { role })

      return
    }

    try {
      const response = await this.$api.get_role.get({ id })

      if (response.data) {
        commit(RoleMutationTypes.SetRole, { role: response.data })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RoleActionTypes.CreateRole]({ commit }, { name }) {
    try {
      await this.$api.create_role.post({
        body: {
          name,
        },
      })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Роль создана', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RoleActionTypes.SetAccessRole]({ commit }, { name, access }) {
    const formData = new FormData()
    formData.append('accesses[1][name]', name)
    formData.append('accesses[1][access]', access)

    try {
      await this.$api.set_access_role.post({ body: formData })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Права добавлены для роли', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RoleActionTypes.UnsetAccessRole]({ commit }, { name, access }) {
    const formData = new FormData()
    formData.append('accesses[1][name]', name)
    formData.append('accesses[1][access]', access)

    try {
      await this.$api.unset_access_role.post({ body: formData })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Права для роли сняты', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RoleActionTypes.UpdateRole]({ state, commit }, { name, old_name }) {
    const { roles } = state

    try {
      const role = await this.$api.update_role.post({
        name: old_name,
        body: {
          name,
        },
      })

      if (roles.find(({ id: $id }) => $id === id)) {
        commit(RoleMutationTypes.UpdateRole, { role })
      }
      commit(RootMutationTypes.SetErrorMessage, { message: 'Роль изменена', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RoleActionTypes.DeleteRole]({ commit }, { name }) {
    try {
      await this.$api.delete_role.post({ name })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Роль удалена', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
