import { OrderActionTypes, OrderMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [OrderActionTypes.LoadOrderCollection](
      { commit, rootState },
      {
        page = 1,
        page_size = 10,
        search = '',
        client_id,
        recipient_id,
        courier_id,
        source_id,
        date_from,
        date_to,
        pay_status,
        delivery_type,
        filterStatus,
        remove_completed_and_approved_filter,
        sort_by = {
          sortBy: 'delivery_interval_to',
          sortDesc: 'asc',
        },
        time = true,
      },
  ) {
    try {
      const response = await this.$api.get_order_collection.fetch({
        params: {
          page,
          page_size,
          search,
          client_id,
          recipient_id,
          courier_id,
          source_id,
          date_from,
          date_to,
          pay_status,
          delivery_type,
          city_id: rootState.auth.city_id,
          status: filterStatus,
          remove_completed_and_approved_filter,
          sort: `${sort_by.sortDesc === 'asc' ? '' : '-'}${sort_by.sortBy}`,
        },
      })

      // TODO update on back req for get time
      if (response.data) {
        if (time) {
          commit(OrderMutationTypes.SetOrderCollection, {
            date_to: response.data.date_to,
            date_from: response.data.date_from,
          })
          return
        }
        commit(OrderMutationTypes.SetOrderCollection, {
          orders: response.data.content,
          pageCount: response.data.pageCount,
          itemCount: response.data.total_count,
          totalPrice: response.data.total_price,
          ...response.data,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [OrderActionTypes.LoadOrderTableConfig]({ commit }) {
    try {
      const config = await this.$api.get_order_collection.get()

      commit(OrderMutationTypes.SetOrderTableConfig, { config })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [OrderActionTypes.LoadOrder]({ state, commit }, { id }) {
    const { orders } = state
    // TODO replace getter
    const order = orders.find(({ $id }) => $id === id)

    if (order) {
      commit(OrderMutationTypes.SetOrder, { order })

      return
    }

    try {
      const response = await this.$api.get_order.get({ id })

      if (response.data) {
        commit(OrderMutationTypes.SetOrder, {
          order: response.data,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [OrderActionTypes.CreateOrder]({ commit }, data) {
    try {
      const order = await this.$api.create_order.post({
        body: { ...data },
      })

      commit(OrderMutationTypes.SetOrder, { order })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Заказ создан', type: 'success' }, { root: true })

      return order
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [OrderActionTypes.UpdateOrder]({ state, commit }, data) {
    const { orders } = state

    try {
      const order = await this.$api.update_order.post({
        id: data.id,
        body: { ...data },
      })

      const index = orders.length ? orders.findIndex(({ order_id }) => order_id === data.id) : -1

      if (index !== -1) {
        commit(OrderMutationTypes.UpdateOrder, { order: order.data, index })
      }
      commit(RootMutationTypes.SetErrorMessage, { message: 'Заказ изменен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },

  async [OrderActionTypes.ChangeOrderStatusManagerAndAdmin]({ state, commit }, { id, status }) {
    try {
      await this.$api.change_order_status_manager_and_admin.post({ id: id, body: { status: status } })

      commit(RootMutationTypes.SetErrorMessage, { message: 'Статус заказа изменен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },

  async [OrderActionTypes.DeleteOrder]({ commit }, { id }) {
    // TODO update after back update
    try {
      await this.$api.delete_order.post({ id, params: { id } })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Заказ удален', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [OrderActionTypes.CreateOrderProduct]({ commit }, data) {
    try {
      await this.$api.create_order_product.post({
        body: { ...data },
      })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Товар добавлен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [OrderActionTypes.UpdateOrderProduct]({ state, commit }, data) {
    const { products } = state

    try {
      const product = await this.$api.update_order_product.post({
        id: data.id,
        body: { ...data },
      })

      if (products.find(({ id: $id }) => $id === id)) {
        commit(OrderMutationTypes.UpdateOrderProduct, { product })
      }
      commit(RootMutationTypes.SetErrorMessage, { message: 'Товар изменен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [OrderActionTypes.DeleteOrderProduct]({ state, commit }, { id }) {
    try {
      const { data } = await this.$api.delete_order_product.post({ id })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Товар удален', type: 'success' }, { root: true })
      return data
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [OrderActionTypes.LoadOrderTableConfig]({ commit }) {
    try {
      const config = await this.$api.get_order_table_config.fetch()

      commit(OrderMutationTypes.SetOrderTableConfig, { config })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [OrderActionTypes.UpdateOrderTableConfig]({ commit }, { settings }) {
    try {
      const formData = new FormData()
      settings.forEach((item, i) => {
        formData.append(`table_columns[${i}][name]`, item.name)
        formData.append(`table_columns[${i}][status]`, item.status)
      })

      const config = await this.$api.update_order_table_config.post({
        body: formData,
      })

      commit(OrderMutationTypes.SetOrderTableConfig, { config })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
