import { DeliveryActionTypes, DeliveryMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [DeliveryActionTypes.LoadDeliveryCollection](
      { commit },
      {
        page = 1,
        page_size = 10,
        search = '',
        status,
        sort_by = {
          sortBy: 'sort',
          sortDesc: 'asc',
        },
      },
  ) {
    try {
      const response = await this.$api.get_delivery_collection.fetch({
        params: {
          page,
          page_size,
          status,
          search,
          sort: `${sort_by.sortDesc === 'asc' ? '' : '-'}${sort_by.sortBy}`,
        },
      })

      if (response.data) {
        commit(DeliveryMutationTypes.SetDeliveryCollection, {
          deliveries: response.data.content,
          pageCount: response.data.pageCount,
          itemCount: response.data.total_count,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [DeliveryActionTypes.LoadDelivery]({ state, commit }, { id }) {
    const { deliveries } = state
    // TODO replace getter
    const delivery = deliveries.find(({ $id }) => $id === id)

    if (delivery) {
      commit(DeliveryMutationTypes.SetDelivery, { delivery })

      return
    }

    try {
      const response = await this.$api.get_delivery.get({ id })

      if (response.data) {
        commit(DeliveryMutationTypes.SetDelivery, { delivery: response.data })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [DeliveryActionTypes.CreateDelivery]({ commit }, { status, sort, name }) {
    try {
      await this.$api.create_delivery.post({
        body: {
          status,
          sort,
          name,
        },
      })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Служба добавлена', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [DeliveryActionTypes.UpdateDelivery]({ state, commit }, { id, status, sort, name }) {
    const { deliveries } = state

    try {
      const delivery = await this.$api.update_delivery.post({
        id,
        body: {
          status,
          sort,
          name,
        },
      })

      if (deliveries.find(({ id: $id }) => $id === id)) {
        commit(DeliveryMutationTypes.UpdateDelivery, {
          delivery,
        })
      }
      commit(RootMutationTypes.SetErrorMessage, { message: 'Данные изменены', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [DeliveryActionTypes.DeleteDelivery]({ commit }, { id }) {
    try {
      await this.$api.delete_delivery.post({ id })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Служба удалена', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [DeliveryActionTypes.SetStatusToDelivery]({ commit }, { deliveries }) {
    const formData = new FormData()
    deliveries.forEach((item, i) => {
      formData.append('delivery-services[' + i + '][status]', item.status)
      formData.append('delivery-services[' + i + '][id]', item.id)
    })

    try {
      await this.$api.set_delivery_status.post({ body: formData })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Статус изменен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
