const CalendarNameSpace = 'calendar'

const CalendarActionTypes = Object.freeze({
  LoadCalendarCollection: 'LoadCalendarCollection',
  LoadCalendar: 'LoadCalendar',
  CreateCalendar: 'CreateCalendar',
  UpdateCalendar: 'UpdateCalendar',
  DeleteCalendar: 'DeleteCalendar',
})

const CalendarMutationTypes = Object.freeze({
  SetCalendarCollection: 'SetCalendarCollection',
  SetCalendar: 'SetCalendar',
  UpdateCalendar: 'UpdateCalendar',
  RemoveCalendar: 'RemoveCalendar',
})

const CalendarGetterTypes = Object.freeze({
  GetNewOrderCollection: 'GetNewOrderCollection',
  GetAssembledOrderCollection: 'GetAssembledOrderCollection',
  GetTakeByCourierOrderCollection: 'GetTakeByCourierOrderCollection',
  GetCompleteOrderCollection: 'GetCompleteOrderCollection',
})

const CalendarOrderStatus = Object.freeze({
  new: 'new',
  assembled: 'assembled',
  completed: 'completed',
  taken_by_courier: 'taken_by_courier',
})


export {
  CalendarNameSpace,
  CalendarActionTypes,
  CalendarMutationTypes,
  CalendarGetterTypes,
  CalendarOrderStatus,
}
