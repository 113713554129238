import { CityActionTypes, CityMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [CityActionTypes.LoadCityCollection](
      { commit },
      {
        page = 1,
        page_size = 10,
        search = '',
        status,
        sort_by = {
          sortBy: 'name',
          sortDesc: 'asc',
        },
      } = {},
  ) {
    try {
      const response = await this.$api.get_city_collection.fetch({
        params: {
          page,
          page_size,
          search,
          status,
          sort: `${sort_by.sortDesc === 'asc' ? '' : '-'}${sort_by.sortBy}`,
        },
      })

      if (response && response.data) {
        commit(CityMutationTypes.SetCityCollection, {
          cities: response.data.content,
          pageCount: response.data.pageCount,
          itemCount: response.data.total_count,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },

  async [CityActionTypes.LoadCityFullCollection](
      { commit },
      {
        page = 1,
        page_size = 100,
        search = '',
        status = 1,
        sort_by = {
          sortBy: 'name',
          sortDesc: 'asc',
        },
      } = {},
  ) {
    try {
      const response = await this.$api.get_city_collection.fetch({
        params: {
          page,
          page_size,
          search,
          status,
          sort: `${sort_by.sortDesc === 'asc' ? '' : '-'}${sort_by.sortBy}`,
        },
      })

      if (response.data) {
        commit(CityMutationTypes.SetCityFullCollection, {
          cities: response.data.content,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },

  async [CityActionTypes.LoadCity]({ state, commit }, { id }) {
    const { cities } = state
    // TODO replace getter
    const city = cities.find(({ $id }) => $id === id)

    if (city) {
      commit(CityMutationTypes.SetCity, { city })

      return
    }

    try {
      const response = await this.$api.get_city.get({ id })

      if (response.data) {
        commit(CityMutationTypes.SetCity, {
          city: response.data,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },

  async [CityActionTypes.CreateCity]({ commit }, { status = 1, name }) {
    try {
      await this.$api.create_city.post({
        body: {
          status,
          name,
        },
      })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Город добавлен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },

  async [CityActionTypes.UpdateCity]({ state, commit }, { id, status, name }) {
    const { cities } = state

    try {
      const city = await this.$api.update_city.post({
        id,
        body: {
          status,
          name,
        },
      })

      if (cities.find(({ id: $id }) => $id === id)) {
        commit(CityMutationTypes.UpdateCity, { city })
        commit(RootMutationTypes.SetErrorMessage, { message: 'Информация изменена', type: 'success' }, { root: true })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },

  async [CityActionTypes.DeleteCity]({ commit }, { id }) {
    // TODO update after back update
    try {
      await this.$api.delete_city.post({ id, params: { id } })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Город удален', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },

  async [CityActionTypes.SetStatusToCity]({ commit }, { cities }) {
    const formData = new FormData()
    cities.forEach((item, i) => {
      formData.append('cities[' + i + '][status]', item.status)
      formData.append('cities[' + i + '][id]', item.id)
    })

    try {
      await this.$api.set_city_status.post({ body: formData })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Статус изменен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },
}
