import { StaffMutationTypes } from './types'

export const mutations = {
  [StaffMutationTypes.SetStaffCollection](state, { staffCollection, pageCount, itemCount, date_from, date_to }) {
    state.staffCollection = staffCollection
    state.pageCount = pageCount
    state.itemCount = itemCount
    state.date_from = new Date(date_from * 1000)
    state.date_to = new Date(date_to * 1000)
  },
  [StaffMutationTypes.SetStaff](state, { staff = {} }) {
    state.staff = staff
  },
  [StaffMutationTypes.UpdateStaff](state, { staff = {} }) {
    state.staffCollection[staff.id] = staff
  },
}
