import { FileMutationTypes } from './types'

export const mutations = {
  [FileMutationTypes.SetFile](state, { file }) {
    state.file = file
  },
  [FileMutationTypes.SetFiles](state, { files }) {
    state.files = files
  },
}
