import Vue from 'vue'
import { stringify } from 'querystring'
import { normalize, resolve } from 'path'
import clearDeep from 'clean-deep'

const $vm = Vue.prototype
class BaseApiService {
  constructor(resource) {
    if (!resource) throw new Error('path not provided')
    this.baseUrl = process.env.VUE_APP_API_URL
    this.resource = resource
    this.path = resolve(this.baseUrl, this.resource)
  }

  handleErrors({ response }) {
    const { data } = response ? response : { data: { message: 'Ошибка получения данных', status: 500, errors: [] } }
    const { message, status, errors } = data

    if (errors) {
      for (const name in errors) {
        if (errors.hasOwnProperty(name)) {
          throw errors[name].join()
        } else {
          throw new Error('Неизвестная ошибка')
        }
      }
    }

    throw message ? message : status
  }

  url({ id, name, query }) {
    const path = this.path
    return normalize(id || name ? path.concat('/', id ? id : name) : query ? path.concat('?', query) : path)
  }
}

class ReadOnlyApiService extends BaseApiService {
  constructor(resource) {
    super(resource)
  }

  async fetch({ params = {} } = {}) {
    if (params.date_from) {
      const from = new Date(new Date(params.date_from)).getTime()

      params.date_from = parseInt((from - params.date_from.getTimezoneOffset() * 60000) / 1000, 10)
    }
    if (params.date_to) {
      const to = new Date(new Date(params.date_to)).getTime()

      params.date_to = parseInt((to - params.date_to.getTimezoneOffset() * 60000) / 1000, 10)
    }
    const query = stringify(clearDeep(params))

    const url = this.url({ query })

    try {
      const response = await $vm.$http(url)

      return response && response.data ? response.data : null
    } catch (error) {
      this.handleErrors(error)
    }
  }

  async get({ params = {}, id = '' } = {}) {
    try {
      const query = stringify(clearDeep(params))

      const url = this.url({ query, id })

      const response = await $vm.$http(url)

      return response && response.data ? response.data : null
    } catch (error) {
      this.handleErrors(error)
    }
  }
}

class ModelApiService extends ReadOnlyApiService {
  constructor(resource) {
    super(resource)
  }

  async post({ body = {}, params = {}, id = '', name = '' } = {}) {
    try {
      const query = stringify(clearDeep(params))

      const url = this.url({ query, id, name })

      const { data } = await $vm.$http(url, {
        method: 'POST',
        data: body,
      })

      return data
    } catch (error) {
      this.handleErrors(error)
    }
  }

  async postFile({ body = {} } = {}) {
    try {
      const { data } = await $vm.$http(this.path, {
        method: 'POST',
        data: body,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      return data
    } catch (error) {
      this.handleErrors(error)
    }
  }
}

class AuthLogin extends ModelApiService {
  constructor() {
    super('/auth/login')
  }
}

class AuthResetPassword extends ModelApiService {
  constructor() {
    super('/auth/reset-password')
  }
}

// Analytic

class GetOrderDataSourceGraph extends ReadOnlyApiService {
  constructor() {
    super('/analitic/get-order-data-by-source-graph')
  }
}

class GetDeliveryDiagram extends ReadOnlyApiService {
  constructor() {
    super('/analitic/get-order-by-source-diagram')
  }
}

class GetCostsDiagram extends ReadOnlyApiService {
  constructor() {
    super('/analitic/get-costs-diagram')
  }
}

// Analytic

// City

class GetCityCollection extends ReadOnlyApiService {
  constructor() {
    super('/city/index')
  }
}

class UpdateCity extends ModelApiService {
  constructor() {
    super('/city/update')
  }
}

class CreateCity extends ModelApiService {
  constructor() {
    super('/city/create')
  }
}

class SetCityStatus extends ModelApiService {
  constructor() {
    super('/city/set-status-to-cities')
  }
}

class GetCity extends ReadOnlyApiService {
  constructor() {
    super('/city/view')
  }
}

class DeleteCity extends ModelApiService {
  constructor() {
    super('/city/delete')
  }
}
// City

// Client
class GetClientCollection extends ReadOnlyApiService {
  constructor() {
    super('/client/index')
  }
}
class GetClient extends ReadOnlyApiService {
  constructor() {
    super('/client/view')
  }
}

class CreatClient extends ModelApiService {
  constructor() {
    super('/client/create')
  }
}

class UpdateClient extends ModelApiService {
  constructor() {
    super('/client/update')
  }
}
class DeleteClient extends ModelApiService {
  constructor() {
    super('/client/delete')
  }
}

class GetClientTableColumn extends ReadOnlyApiService {
  constructor() {
    super('/client/table-columns')
  }
}

class UpdateClientTableColumn extends ModelApiService {
  constructor() {
    super('/client/set-unset-table-column')
  }
}
// Client

// Cost
class GetCostCollection extends ReadOnlyApiService {
  constructor() {
    super('/cost/index')
  }
}

class UpdateCost extends ModelApiService {
  constructor() {
    super('/cost/update')
  }
}

class CreateCost extends ModelApiService {
  constructor() {
    super('/cost/create')
  }
}

class GetCost extends ReadOnlyApiService {
  constructor() {
    super('/cost/view')
  }
}

class DeleteCost extends ModelApiService {
  constructor() {
    super('/cost/delete')
  }
}
// Cost

// Delivery
class GetDeliveryCollection extends ReadOnlyApiService {
  constructor() {
    super('/delivery-service/index')
  }
}

class GetDelivery extends ReadOnlyApiService {
  constructor() {
    super('/delivery-service/view')
  }
}

class UpdateDelivery extends ModelApiService {
  constructor() {
    super('/delivery-service/update')
  }
}

class CreateDelivery extends ModelApiService {
  constructor() {
    super('/delivery-service/create')
  }
}

class DeleteDelivery extends ModelApiService {
  constructor() {
    super('/delivery-service/delete/')
  }
}

class SetDeliveryStatus extends ModelApiService {
  constructor() {
    super('/delivery-service/set-status-to-delivery-services')
  }
}
// Delivery

// Order Calendar
class GetOrderCalendarCollection extends ReadOnlyApiService {
  constructor() {
    super('/order-calendar/index')
  }
}
class UpdateOrderCalendar extends ModelApiService {
  constructor() {
    super('/order-calendar/table-columns')
  }
}
class UpdateCalendarOrderFlorist extends ReadOnlyApiService {
  constructor() {
    super('/order-calendar/set-unset-florist')
  }
}
// Order Calendar

// Order
class GetOrderCollection extends ReadOnlyApiService {
  constructor() {
    super('/order/index')
  }
}

class GetOrderTableColumn extends ReadOnlyApiService {
  constructor() {
    super('/order/table-columns')
  }
}

class UpdateOrderTableColumn extends ModelApiService {
  constructor() {
    super('/order/set-unset-table-column')
  }
}

class GetOrder extends ReadOnlyApiService {
  constructor() {
    super('/order/view')
  }
}

class UpdateOrder extends ModelApiService {
  constructor() {
    super('/order/update')
  }
}

class CreateOrder extends ModelApiService {
  constructor() {
    super('/order/create')
  }
}

class DeleteOrder extends ModelApiService {
  constructor() {
    super('/order/delete/')
  }
}

class ChangeOrderStatusManagerAndAdmin extends ModelApiService {
  constructor() {
    super('/order/change-order-status-to-manager-and-admin/')
  }
}
// Order
// Product
class UpdateOrderProduct extends ModelApiService {
  constructor() {
    super('/order/update-product')
  }
}

class CreateOrderProduct extends ModelApiService {
  constructor() {
    super('/order/create-product')
  }
}

class DeleteOrderProduct extends ModelApiService {
  constructor() {
    super('/order/product-delete')
  }
}
// Product
// Order Route
class GetOrderRouteCollection extends ReadOnlyApiService {
  constructor() {
    super('/order-route/index')
  }
}

class GetOrderRouteTableConfig extends ReadOnlyApiService {
  constructor() {
    super('/order-route/table-columns')
  }
}

class UpdateOrderRouteTableConfig extends ModelApiService {
  constructor() {
    super('/order-route/set-unset-table-column')
  }
}

class ChangeOrderStatusToCourier extends ModelApiService {
  constructor() {
    super('/order-route/change-order-status-to-courier')
  }
}
// Order Route
// Order Source
class GetOrderSourceCollection extends ReadOnlyApiService {
  constructor() {
    super('/order-sources/index')
  }
}
class GetOrderSource extends ReadOnlyApiService {
  constructor() {
    super('/order-sources/view')
  }
}

class CreatOrderSource extends ModelApiService {
  constructor() {
    super('/order-sources/create')
  }
}

class UpdateOrderSource extends ModelApiService {
  constructor() {
    super('/order-sources/update')
  }
}
class UpdateOrderSourceConfig extends ModelApiService {
  constructor() {
    super('/order-sources/set-status-order-source')
  }
}
class DeleteOrderSource extends ModelApiService {
  constructor() {
    super('/order-sources/delete')
  }
}
class SetOrderSourceStatus extends ModelApiService {
  constructor() {
    super('/order-sources/set-status-to-order-sources')
  }
}

// Order Source
// Role
class GetRoleCollection extends ReadOnlyApiService {
  constructor() {
    super('/role/index')
  }
}
class GetRole extends ReadOnlyApiService {
  constructor() {
    super('/role/view')
  }
}

class CreateRole extends ModelApiService {
  constructor() {
    super('/role/create')
  }
}
class UpdateRole extends ModelApiService {
  constructor() {
    super('/role/update')
  }
}
class SetAccessRole extends ModelApiService {
  constructor() {
    super('/role/set-access')
  }
}
class UnsetAccessRole extends ModelApiService {
  constructor() {
    super('/role/unset-access')
  }
}
class DeleteRole extends ModelApiService {
  constructor() {
    super('/role/delete')
  }
}
// Role
// Staff
class GetStaffCollection extends ReadOnlyApiService {
  constructor() {
    super('/staff/index')
  }
}
class GetStaff extends ReadOnlyApiService {
  constructor() {
    super('/staff/view')
  }
}
class CreateStaff extends ModelApiService {
  constructor() {
    super('/staff/create')
  }
}
class UpdateStaff extends ModelApiService {
  constructor() {
    super('/staff/update')
  }
}
class DeleteStaff extends ModelApiService {
  constructor() {
    super('/staff/delete')
  }
}
class UpdateCityStaff extends ModelApiService {
  constructor() {
    super('/staff/update-city')
  }
}
// Staff
// Recipients
class GetRecipientCollection extends ReadOnlyApiService {
  constructor() {
    super('/recipient/index')
  }
}
class GetRecipient extends ReadOnlyApiService {
  constructor() {
    super('/recipient/view')
  }
}
class CreateRecipient extends ModelApiService {
  constructor() {
    super('/recipient/create')
  }
}
class UpdateRecipient extends ModelApiService {
  constructor() {
    super('/recipient/update')
  }
}
class DeleteRecipient extends ModelApiService {
  constructor() {
    super('/recipient/delete')
  }
}

class GetRecipientTableColumn extends ReadOnlyApiService {
  constructor() {
    super('/recipient/table-columns')
  }
}

class UpdateRecipientTableColumn extends ModelApiService {
  constructor() {
    super('/recipient/set-unset-table-column')
  }
}
// Recipients
// Map
class OrderMap extends ReadOnlyApiService {
  constructor() {
    super('/order-map/index')
  }
}
class SetCourierToOrder extends ModelApiService {
  constructor() {
    super('/order-map/set-courier-to-order')
  }
}
class DeleteCourierFromOrder extends ModelApiService {
  constructor() {
    super('/order-map/delete-courier-from-order')
  }
}
class SetOrderToDelivery extends ModelApiService {
  constructor() {
    super('/order-map/set-order-to-delivery')
  }
}
// Map
// Files
class LoadFile extends ModelApiService {
  constructor() {
    super('/upload/one')
  }
}
class LoadFiles extends ModelApiService {
  constructor() {
    super('/upload/many')
  }
}
// Files

export const $api = {
  auth: new AuthLogin(),
  reset_password: new AuthResetPassword(),

  getCostsDiagram: new GetCostsDiagram(),
  getDeliveryDiagram: new GetDeliveryDiagram(),
  getOrderDataSourceGraph: new GetOrderDataSourceGraph(),

  get_city_collection: new GetCityCollection(),
  get_city: new GetCity(),
  create_city: new CreateCity(),
  update_city: new UpdateCity(),
  delete_city: new DeleteCity(),
  set_city_status: new SetCityStatus(),

  get_client_collection: new GetClientCollection(),
  get_client: new GetClient(),
  create_client: new CreatClient(),
  update_client: new UpdateClient(),
  delete_client: new DeleteClient(),
  get_client_table_config: new GetClientTableColumn(),
  update_client_table_config: new UpdateClientTableColumn(),

  get_cost_collection: new GetCostCollection(),
  get_cost: new GetCost(),
  create_cost: new CreateCost(),
  update_cost: new UpdateCost(),
  delete_cost: new DeleteCost(),

  get_delivery_collection: new GetDeliveryCollection(),
  get_delivery: new GetDelivery(),
  create_delivery: new CreateDelivery(),
  update_delivery: new UpdateDelivery(),
  delete_delivery: new DeleteDelivery(),
  set_delivery_status: new SetDeliveryStatus(),

  get_order_calendar_collection: new GetOrderCalendarCollection(),
  update_order_calendar: new UpdateOrderCalendar(),
  update_calendar_order_florist: new UpdateCalendarOrderFlorist(),

  get_order_collection: new GetOrderCollection(),
  get_order_table_config: new GetOrderTableColumn(),
  update_order_table_config: new UpdateOrderTableColumn(),
  get_order: new GetOrder(),
  update_order: new UpdateOrder(),
  create_order: new CreateOrder(),
  delete_order: new DeleteOrder(),
  change_order_status_manager_and_admin: new ChangeOrderStatusManagerAndAdmin(),

  update_order_product: new UpdateOrderProduct(),
  create_order_product: new CreateOrderProduct(),
  delete_order_product: new DeleteOrderProduct(),

  get_order_route_collection: new GetOrderRouteCollection(),
  get_order_route_table_config: new GetOrderRouteTableConfig(),
  update_order_route_table_config: new UpdateOrderRouteTableConfig(),
  change_order_status_to_courier: new ChangeOrderStatusToCourier(),

  get_order_source_collection: new GetOrderSourceCollection(),
  get_order_source: new GetOrderSource(),
  create_order_source: new CreatOrderSource(),
  update_order_source: new UpdateOrderSource(),
  update_order_source_config: new UpdateOrderSourceConfig(),
  delete_order_source: new DeleteOrderSource(),
  set_order_source_status: new SetOrderSourceStatus(),

  get_role_collection: new GetRoleCollection(),
  get_role: new GetRole(),
  create_role: new CreateRole(),
  update_role: new UpdateRole(),
  set_access_role: new SetAccessRole(),
  unset_access_role: new UnsetAccessRole(),
  delete_role: new DeleteRole(),

  get_staff_collection: new GetStaffCollection(),
  get_staff: new GetStaff(),
  create_staff: new CreateStaff(),
  update_staff: new UpdateStaff(),
  delete_staff: new DeleteStaff(),
  update_city_staff: new UpdateCityStaff(),

  get_recipient_collection: new GetRecipientCollection(),
  get_recipient: new GetRecipient(),
  create_recipient: new CreateRecipient(),
  update_recipient: new UpdateRecipient(),
  delete_recipient: new DeleteRecipient(),
  get_recipient_table_config: new GetRecipientTableColumn(),
  update_recipient_table_config: new UpdateRecipientTableColumn(),

  get_map_orders_collection: new OrderMap(),
  set_courier_to_order: new SetCourierToOrder(),
  delete_courier_from_order: new DeleteCourierFromOrder(),
  set_order_to_delivery: new SetOrderToDelivery(),

  load_file: new LoadFile(),
  load_files: new LoadFiles(),
}
