import { RoleMutationTypes } from './types'

export const mutations = {
  [RoleMutationTypes.SetRoleCollection](state, { roles, pageCount }) {
    state.roles = roles
    state.pageCount = pageCount
  },
  [RoleMutationTypes.SetRole](state, { role = {} }) {
    state.role = role
  },
  [RoleMutationTypes.UpdateRole](state, { role = {} }) {
    state.roles[role.id] = role
  },
}
