const RecipientNameSpace = 'recipient'

const RecipientActionTypes = Object.freeze({
  LoadRecipientCollection: 'LoadRecipientCollection',
  LoadRecipientTableConfig: 'LoadRecipientTableConfig',
  UpdateRecipientTableConfig: 'UpdateRecipientTableConfig',
  LoadRecipient: 'LoadRecipient',
  LoadRecipientByPhone: 'LoadRecipientByPhone',
  CreateRecipient: 'CreateRecipient',
  UpdateRecipient: 'UpdateRecipient',
  DeleteRecipient: 'DeleteRecipient',
})

const RecipientMutationTypes = Object.freeze({
  SetRecipientCollection: 'SetRecipientCollection',
  SetRecipientTableConfig: 'SetRecipientTableConfig',
  UpdateRecipientTableConfig: 'UpdateRecipientTableConfig',
  SetRecipient: 'SetRecipient',
  UpdateRecipient: 'UpdateRecipient',
  RemoveRecipient: 'RemoveRecipient',
})

const RecipientGetterTypes = Object.freeze({})

export { RecipientNameSpace, RecipientActionTypes, RecipientMutationTypes, RecipientGetterTypes }
