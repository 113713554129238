import { RootMutationTypes } from './types'

const mutations = {
  [RootMutationTypes.SetErrorMessage](state, { type, message = '', errors }) {
    // TODO move to action
    state.message = message
    state.type = type
    errors = ''

    if (errors) {
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          errors[key].forEach(item => {
            errors += `${item}\n`
          });
        }
      }
    }

    this._vm.$toast[type](`${message}\n${errors}`, {
      position: 'bottom-right',
    })

    setTimeout(() => {
      state.message = ''
      state.type = ''
    }, 3000)
  },
  [RootMutationTypes.SetLoadedState](state, { loaded = true }) {
    state.loaded = loaded
  },
}

export { mutations }
