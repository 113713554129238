import Vue from 'vue';
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Vue.component(FeatherIcon.name, FeatherIcon);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
