export default [
  {
    path: '/list-of-cities',
    name: 'list-of-cities',
    component: () => import('@/views/pages/list-of-cities/ListOfCities'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'Список городов',
      permission: 'settingAccess',
    },
  },
]
