// sources-of-orders
export default [
  {
    path: '/sources-of-orders',
    name: 'sources-of-orders',
    component: () =>
      import('@/views/pages/sources-of-orders/SourcesOfOrders'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'Источники заказов',
      permission: 'settingAccess',
    },
  },
]
