import { CostActionTypes, CostMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [CostActionTypes.LoadCostCollection](
      { commit, rootState },
      {
        page = 1,
        page_size = 10,
        date_from,
        date_to,
        search = '',
        manager_id,
        type,
        source_id,
        sort_by = {
          sortBy: 'price',
          sortDesc: 'asc',
        },
        time = true,
      } = {},
  ) {
    try {
      const response = await this.$api.get_cost_collection.fetch({
        params: {
          page,
          page_size,
          date_from,
          date_to,
          search,
          manager_id,
          type,
          source_id,
          city_id: rootState.auth.city_id,
          sort: `${sort_by.sortDesc === 'asc' ? '' : '-'}${sort_by.sortBy}`,
        },
      })
      
      if (response.data) {
        if (time) {
          commit(CostMutationTypes.SetCostCollection, {
            date_to: response.data.date_to,
            date_from: response.data.date_from,
          })
          return
        }
  
        commit(CostMutationTypes.SetCostCollection, {
          bills: response.data.content,
          pageCount: response.data.pageCount,
          itemCount: response.data.total_count,
          ...response.data,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },

  async [CostActionTypes.LoadCost]({ state, commit }, { id }) {
    const { bills } = state
    // TODO replace getter
    const cost = bills.find(({ $id }) => $id === id)

    if (cost) {
      commit(CostMutationTypes.SetCost, { cost })

      return
    }

    try {
      const response = await this.$api.get_cost.get({ id })

      if (response.data) {
        commit(CostMutationTypes.SetCost, { cost: response.data })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [CostActionTypes.CreateCost]({ commit }, { name, price, date, type, photo_url, manager_user_id, city_id }) {
    try {
      await this.$api.create_cost.post({
        body: {
          name,
          price,
          date,
          type,
          photo_url,
          manager_user_id,
          city_id,
        },
      })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Успешно', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [CostActionTypes.UpdateCost]({ state, commit }, { id, name, price, date, type, photo_url, manager_user_id, city_id }) {
    const { bills } = state

    try {
      const cost = await this.$api.update_cost.post({
        id,
        body: {
          name,
          price,
          date,
          type,
          photo_url,
          manager_user_id,
          city_id,
        },
      })

      if (bills.find(({ id: $id }) => $id === id)) {
        commit(CostMutationTypes.UpdateCost, { cost })
      }

      commit(RootMutationTypes.SetErrorMessage, { message: 'Успешно', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [CostActionTypes.DeleteCost]({ commit }, { id }) {
    try {
      await this.$api.delete_cost.post({ params: { id } })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Успешно', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
