import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import analytics from './routes/analytics/index'
import clients from './routes/clients/index'
import receivers from './routes/receivers/index'
import orderBase from './routes/order-base/index'
import orderCalendar from './routes/calendar-order/index'
import calendarOfExpenses from './routes/calendar-of-expenses/index'
import orderMap from './routes/order-map/index'
import routesPage from './routes/routes-page/index'
import baseOfStaff from './routes/base-of-staff/index'
import roles from './routes/roles/index'
import sourcesOfOrders from './routes/sources-of-orders/index'
import courierServices from './routes/courier-services/index'
import listOfCities from './routes/list-of-cities/index'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'order-calendar' } },
    ...analytics,
    ...clients,
    ...receivers,
    ...orderBase,
    ...orderCalendar,
    ...calendarOfExpenses,
    ...orderMap,
    ...routesPage,
    ...baseOfStaff,
    ...roles,
    ...sourcesOfOrders,
    ...courierServices,
    ...listOfCities,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const permisions = store.state.auth.userData ? store.state.auth.userData.permisions : []

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  if (to.meta.permission) {
    if (!permisions.includes(to.meta.permission)) {
      return next({ name: _.name })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
