import Vue from 'vue'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // baseURL: 'http://api.veryberrylab.avada-media-dev1.od.ua',
  headers: {
    common: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken') || ''}`,
    },
  },
})

axiosIns.interceptors.response.use(
    response => response,
    err => {
      if (err.response && err.response.status === 401) {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) return

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userData');
        window.location.replace('/');
      }

      throw err;
    },
)

Vue.prototype.$http = axiosIns

export default axiosIns
