import { RecipientActionTypes, RecipientMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [RecipientActionTypes.LoadRecipientCollection](
      { commit, rootState },
      {
        page = 1,
        page_size = 10,
        search = '',
        date_to,
        date_from,
        phone,
        sort_by = {
          sortBy: 'full_name',
          sortDesc: 'asc',
        },
        time = true,
      },
  ) {
    try {
      const response = await this.$api.get_recipient_collection.fetch({
        params: {
          page,
          page_size,
          search,
          date_to,
          date_from,
          phone,
          city_id: rootState.auth.city_id,
          sort: `${sort_by.sortDesc === 'asc' ? '' : '-'}${sort_by.sortBy}`,
        },
      })

      if (response.data) {
        if (time) {
          commit(RecipientMutationTypes.SetRecipientCollection, {
            date_to: response.data.date_to,
            date_from: response.data.date_from,
          })
          return
        }

        commit(RecipientMutationTypes.SetRecipientCollection, {
          recipientCollection: response.data.content,
          pageCount: response.data.pageCount,
          itemCount: response.data.total_count,
          ...response.data,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RecipientActionTypes.LoadRecipientByPhone]({ commit }, { page = 1, page_size = 10, search = '', date_to, date_from, phone }) {
    try {
      const response = await this.$api.get_recipient_collection.fetch({
        params: {
          page,
          page_size,
          search,
          date_to,
          date_from,
          phone,
        },
      })

      if (response.data) {
        commit(RecipientMutationTypes.SetRecipient, { recipient: response.data.content })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RecipientActionTypes.LoadRecipient]({ state, commit }, { id }) {
    const { recipientCollection } = state

    const recipient = recipientCollection.find(({ $id }) => $id === id)

    if (recipient) {
      commit(RecipientMutationTypes.SetRecipient, { recipient })

      return
    }

    try {
      const response = await this.$api.get_recipient.get({ id })

      if (response.data) {
        commit(RecipientMutationTypes.SetRecipient, { recipient: response.data })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RecipientActionTypes.CreateRecipient]({ commit }, { city_id, full_name, phone }) {
    try {
      const { data } = await this.$api.create_recipient.post({
        body: {
          city_id,
          full_name,
          phone,
        },
      })
      commit(RecipientMutationTypes.SetRecipient, { recipient: data })

      commit(RootMutationTypes.SetErrorMessage, { message: 'Получатель добавлен', type: 'success' }, { root: true })

      return true
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RecipientActionTypes.UpdateRecipient]({ state, commit }, { id, full_name, phone }) {
    const { recipientCollection } = state

    try {
      const recipient = await this.$api.update_recipient.post({
        id,
        body: {
          full_name,
          phone,
        },
      })

      if (recipientCollection.find(({ id: $id }) => $id === id)) {
        commit(RecipientMutationTypes.UpdateRecipient, { recipient })
      }
      commit(RootMutationTypes.SetErrorMessage, { message: 'Изменения сохранены', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RecipientActionTypes.DeleteRecipient]({ commit }, { id }) {
    try {
      await this.$api.delete_recipient.post({ id })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Успешно', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RecipientActionTypes.LoadRecipientTableConfig]({ commit }) {
    try {
      const config = await this.$api.get_recipient_table_config.fetch()

      commit(RecipientMutationTypes.SetRecipientTableConfig, { config })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RecipientActionTypes.UpdateRecipientTableConfig]({ commit }, { settings }) {
    try {
      const formData = new FormData()
      settings.forEach((item, i) => {
        formData.append(`table_columns[${i}][name]`, item.name)
        formData.append(`table_columns[${i}][status]`, item.status)
      })

      const config = await this.$api.update_recipient_table_config.post({
        body: formData,
      })

      commit(RecipientMutationTypes.SetRecipientTableConfig, { config })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
