import { RouteActionTypes, RouteMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [RouteActionTypes.LoadRouteCollection](
      { commit, rootState },
      {
        page = 1,
        page_size = 10,
        courier_id,
        search = '',
        sort_by = {
          sortBy: 'interval_to',
          sortDesc: 'asc',
        },
        default_sort_by_order,
      },
  ) {
    try {
      const response = await this.$api.get_order_route_collection.fetch({
        params: {
          page,
          page_size,
          courier_id,
          sort: `${sort_by.sortDesc === 'asc' ? '' : '-'}${sort_by.sortBy}`,
          search,
          city_id: rootState.auth.city_id,
          default_sort_by_order,
        },
      })

      if (response.data) {
        commit(RouteMutationTypes.SetRouteCollection, {
          routes: response.data.content,
          pageCount: response.data.pageCount,
          itemCount: response.data.total_count,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
  async [RouteActionTypes.LoadRouteTableConfig]({ commit }) {
    try {
      const config = await this.$api.get_order_route_table_config.fetch()

      commit(RouteMutationTypes.SetRouteTableConfig, { config })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [RouteActionTypes.UpdateRouteTableConfig]({ commit }, { settings }) {
    try {
      const formData = new FormData()
      settings.forEach((item, i) => {
        formData.append(`table_columns[${i}][name]`, item.name)
        formData.append(`table_columns[${i}][status]`, item.status)
      })

      const config = await this.$api.update_order_route_table_config.post({
        body: formData,
      })

      commit(RouteMutationTypes.SetRouteTableConfig, { config })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
  async [RouteActionTypes.ChangeOrderStatusToCourier]({ commit }, { id, status }) {
    try {
      await this.$api.change_order_status_to_courier.post({
        id: id,
        body: { status },
      })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Статус изменен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
