export default [
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('@/views/pages/analytics/Analytics'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      permission: 'analyticAccess',
    },
  },
]
