export default [
  {
    path: '/order-base',
    name: 'order-base',
    component: () => import('@/views/pages/order-base/OrderBase'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'База заказов',
      permission: 'ordersAccess',
    },
  },
  {
    path: '/order-base/:id',
    name: 'order-base-item',
    component: () => import('@/views/pages/order-base/OrderBaseItem'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'Карточка заказа',
      permission: 'ordersAccess',
    },
  },
  {
    path: '/order-base/new',
    name: 'order-base-new',
    component: () => import('@/views/pages/order-base/OrderBaseItem'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'Карточка заказа',
      permission: 'ordersAccess',
    },
  },
]
