export default [
  {
    path: '/courier-services',
    name: 'courier-services',
    component: () => import('@/views/pages/courier-services/CourierServices'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'Курьерские службы',
      permission: 'settingAccess',
    },
  },
]
