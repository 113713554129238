const SourceNameSpace = 'source'

const SourceActionTypes = Object.freeze({
  LoadSourceCollection: 'LoadSourceCollection',
  LoadSource: 'LoadSource',
  CreateSource: 'CreateSource',
  UpdateSource: 'UpdateSource',
  DeleteSource: 'DeleteSource',
  SetStatusToSource: 'SetStatusToSource',
})

const SourceMutationTypes = Object.freeze({
  SetSourceCollection: 'SetSourceCollection',
  SetSource: 'SetSource',
  UpdateSource: 'UpdateSource',
  RemoveSource: 'RemoveSource',
})

const SourceGetterTypes = Object.freeze({})

export { SourceNameSpace, SourceActionTypes, SourceMutationTypes, SourceGetterTypes }
