import { RecipientMutationTypes } from './types'

export const mutations = {
  [RecipientMutationTypes.SetRecipientCollection](state, { recipientCollection, pageCount, itemCount, date_to, date_from }) {
    state.recipientCollection = recipientCollection
    state.pageCount = pageCount
    state.itemCount = itemCount
    state.date_from = new Date(date_from * 1000)
    state.date_to = new Date(date_to * 1000)
  },
  [RecipientMutationTypes.SetRecipientTableConfig](state, { config }) {
    state.config = config
  },
  [RecipientMutationTypes.UpdateRecipientTableConfig](state, { config }) {
    state.config = config
  },
  [RecipientMutationTypes.SetRecipient](state, { recipient = {} }) {
    state.recipient = recipient
  },
  [RecipientMutationTypes.UpdateRecipient](state, { recipient = {} }) {
    state.recipientCollection[recipient.id] = recipient
  },
}
