const RoleNameSpace = 'role'

const RoleActionTypes = Object.freeze({
  LoadRoleCollection: 'LoadRoleCollection',
  LoadRole: 'LoadRole',
  CreateRole: 'CreateRole',
  UpdateRole: 'UpdateRole',
  SetAccessRole: 'SetAccessRole',
  UnsetAccessRole: 'UnsetAccessRole',
  DeleteRole: 'DeleteRole',
})

const RoleMutationTypes = Object.freeze({
  SetRoleCollection: 'SetRoleCollection',
  SetRole: 'SetRole',
  UpdateRole: 'UpdateRole',
  RemoveRole: 'RemoveRole',
})

const RoleGetterTypes = Object.freeze({})

export { RoleNameSpace, RoleActionTypes, RoleMutationTypes, RoleGetterTypes }
