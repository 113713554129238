const ClientNameSpace = 'client'

const ClientActionTypes = Object.freeze({
  LoadClientCollection: 'LoadClientCollection',
  LoadClient: 'LoadClient',
  LoadClientTableConfig: 'LoadClientTableConfig',
  UpdateClientTableConfig: 'UpdateClientTableConfig',
  CreateClient: 'CreateClient',
  UpdateClient: 'UpdateClient',
  DeleteClient: 'DeleteClient',
})

const ClientMutationTypes = Object.freeze({
  SetClientCollection: 'SetClientCollection',
  SetClientTableConfig: 'SetClientTableConfig',
  UpdateClientTableConfig: 'UpdateClientTableConfig',
  SetClient: 'SetClient',
  UpdateClient: 'UpdateClient',
  RemoveClient: 'RemoveClient',
})

const ClientGetterTypes = Object.freeze({})

export { ClientNameSpace, ClientActionTypes, ClientMutationTypes, ClientGetterTypes }
