import { StaffActionTypes, StaffMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [StaffActionTypes.LoadStaffCollection](
      { commit },
      {
        date_from = '',
        page = 1,
        page_size = 10,
        search = '',
        sort,
        city_id,
        role,
        sort_by = {
          sortBy: 'full_name',
          sortDesc: 'asc',
        },
      } = {},
  ) {
    try {
      const response = await this.$api.get_staff_collection.fetch({
        params: {
          page,
          page_size,
          sort,
          city_id,
          role,
          birth_date: date_from ? date_from / 1000 : '',
          search,
          sort: `${sort_by.sortDesc === 'asc' ? '' : '-'}${sort_by.sortBy === 'date_from' ? 'birth_date' : sort_by.sortBy}`,
        },
      })

      if (response.data) {
        commit(StaffMutationTypes.SetStaffCollection, {
          staffCollection: response.data.content,
          pageCount: response.data.pageCount,
          itemCount: response.data.total_count,
          ...response.data,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },

  async [StaffActionTypes.LoadStaff]({ state, commit }, { id }) {
    const { staffCollection } = state
    // TODO replace getter
    const staff = staffCollection.find(({ $id }) => $id === id)

    if (staff) {
      commit(StaffMutationTypes.SetStaff, { staff })

      return
    }
    try {
      const response = await this.$api.get_staff.get({ id })

      if (response.data) {
        commit(StaffMutationTypes.SetStaff, { staff: response.data })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [StaffActionTypes.CreateStaff]({ commit }, { full_name, passport_serie, passport_number, passport_issued_date, passport_issued_by, passport_department_code, birth_date, birth_place, registration_place, passport_photo_1, passport_photo_2, phone, password, color, city_id, created_at, role }) {
    try {
      await this.$api.create_staff.post({
        body: {
          full_name,
          passport_serie,
          passport_number,
          passport_issued_date,
          passport_issued_by,
          passport_department_code,
          birth_date,
          birth_place,
          registration_place,
          passport_photo_1,
          passport_photo_2,
          phone,
          password,
          color,
          city_id,
          created_at,
          role,
        },
      })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Сотрудник добавлен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [StaffActionTypes.UpdateStaff]({ state, commit }, { id, full_name, passport_serie, passport_number, passport_issued_date, passport_issued_by, passport_department_code, birth_date, birth_place, registration_place, passport_photo_1, passport_photo_2, phone, password, color, city_id, created_at, role }) {
    const { staffCollection } = state

    try {
      const staff = await this.$api.update_staff.post({
        id,
        body: {
          full_name,
          passport_serie,
          passport_number,
          passport_issued_date,
          passport_issued_by,
          passport_department_code,
          birth_date,
          birth_place,
          registration_place,
          passport_photo_1,
          passport_photo_2,
          phone,
          password,
          color,
          city_id,
          created_at,
          role,
        },
      })

      if (staffCollection.find(({ id: $id }) => $id === id)) {
        commit(StaffMutationTypes.UpdateStaff, { staff })
      }
      commit(RootMutationTypes.SetErrorMessage, { message: 'Информация обновлена', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [StaffActionTypes.DeleteStaff]({ commit }, { id }) {
    try {
      await this.$api.delete_staff.post({ id })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Успешно', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [StaffActionTypes.UpdateCityStaff]({ commit }, { id, city_id }) {
    try {
      await this.$api.update_city_staff.post({
        id,
        body: {
          city_id,
        },
      })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
