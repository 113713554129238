const OrderNameSpace = 'order'

const OrderActionTypes = Object.freeze({
  LoadOrderCollection: 'LoadOrderCollection',
  LoadOrder: 'LoadOrder',
  LoadOrderTableConfig: 'LoadOrderTableConfig',
  UpdateOrderTableConfig: 'UpdateOrderTableConfig',
  CreateOrder: 'CreateOrder',
  UpdateOrder: 'UpdateOrder',
  DeleteOrder: 'DeleteOrder',
  CreateOrderProduct: 'CreateOrderProduct',
  UpdateOrderProduct: 'UpdateOrderProduct',
  DeleteOrderProduct: 'DeleteOrderProduct',
  ChangeOrderStatusManagerAndAdmin: 'ChangeOrderStatusManagerAndAdmin',
})

const OrderMutationTypes = Object.freeze({
  SetOrderCollection: 'SetOrderCollection',
  SetOrder: 'SetOrder',
  SetOrderTableConfig: 'SetOrderTableConfig',
  UpdateOrderTableConfig: 'UpdateOrderTableConfig',
  UpdateOrder: 'UpdateOrder',
  RemoveOrder: 'RemoveOrder',
  SetOrderProduct: 'SetOrderProduct',
  UpdateOrderProduct: 'UpdateOrderProduct',
  RemoveOrderProduct: 'RemoveOrderProduct',
})

const OrderGetterTypes = Object.freeze({})

const OrderStatus = Object.freeze({
  new: 1,
  assembled: 2,
  take_by_courier: 3,
  complete: 4,
})

const OrderPayStatus = Object.freeze({
  1: 'Оплачен',
  2: 'Оплатит позже на Cloud payments',
  3: 'Оплатит позже на карту',
  4: 'Оплата при получении',
})

export { OrderNameSpace, OrderActionTypes, OrderMutationTypes, OrderGetterTypes, OrderStatus, OrderPayStatus }
