export default [
  {
    path: '/order-calendar',
    name: 'order-calendar',
    component: () => import('@/views/pages/calendar-order/CalendarOrder'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'Календарь Заказов',
    },
  },
]
