import { MapActionTypes, MapMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [MapActionTypes.LoadMapOrdersCollection]({ commit, rootState }, {
    page = 1,
    page_size = 10,
    status,
    courier_id,
    date_from,
    date_to,
    courier_appointed,
    delivery_status,
    default_sort_by_order,
  }) {
    try {
      const response = await this.$api.get_map_orders_collection.fetch({
        params: {
          page,
          page_size,
          status,
          date_from,
          date_to,
          courier_id,
          courier_appointed,
          delivery_status,
          default_sort_by_order,
          city_id: rootState.auth.city_id,
        },
      })

      if (response.data) {
        commit(MapMutationTypes.SetMapOrdersCollection, {
          map_orders: response.data.content,
          pageCount: response.data.pageCount,
          itemCount: response.data.total_count,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
  async [MapActionTypes.SetCourierToOrder]({ commit }, { id, courier_id }) {
    try {
      await this.$api.set_courier_to_order.post({
        id,
        body: {
          courier_id,
        },
      })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
  async [MapActionTypes.SetOrderToDelivery]({ commit }, { id, order }) {
    try {
      await this.$api.set_order_to_delivery.post({
        id,
        body: {
          order,
        },
      })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
  async [MapActionTypes.DeleteCourierFromOrder]({ commit }, { id, courier_id }) {
    try {
      await this.$api.delete_courier_from_order.post({ id, body: { courier_id } })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
