const AnalyticNameSpace = 'analytic'

const AnalyticActionTypes = Object.freeze({
  LoadAnalyticCostDiagram: 'LoadAnalyticCostDiagram',
  LoadAnalyticDeliveryDiagram: 'LoadAnalyticDeliveryDiagram',
  LoadOrderDataSourceGraph: 'LoadOrderDataSourceGraph',
})

const AnalyticMutationTypes = Object.freeze({
  SetAnalyticCostDiagram: 'SetAnalyticCostDiagram',
  SetAnalyticDeliveryDiagram: 'SetAnalyticDeliveryDiagram',
  SetOrderDataSourceGraph: 'SetOrderDataSourceGraph',
})

const AnalyticGetterTypes = Object.freeze({})

export { AnalyticNameSpace, AnalyticActionTypes, AnalyticMutationTypes, AnalyticGetterTypes }
