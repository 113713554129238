export default [
  {
    path: '/order-map',
    name: 'order-map',
    component: () => import('@/views/pages/order-map/OrderMap'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      permission: 'ordersMapAccess',
    },
  },
]
