import { CalendarGetterTypes } from './types'
import { OrderStatus } from '../order/types'

const getters = {
  [CalendarGetterTypes.GetNewOrderCollection]({ orders }) {
    return orders.filter(({ status }) => status === OrderStatus.new)
  },
  [CalendarGetterTypes.GetAssembledOrderCollection]({ orders }) {
    return orders.filter(({ status }) => status === OrderStatus.GetAssembledOrderCollection)
  },
  [CalendarGetterTypes.GetTakeByCourierOrderCollection]({ orders }) {
    return orders.filter(({ status }) => status === OrderStatus.take_by_courier)
  },
  [CalendarGetterTypes.GetCompleteOrderCollection]({ orders }) {
    return orders.filter(({ status }) => status === OrderStatus.complete)
  },
}

export { getters }
