const RouteNameSpace = 'route'

const RouteActionTypes = Object.freeze({
  LoadRouteCollection: 'LoadRouteCollection',
  LoadRouteTableConfig: 'LoadRouteTableConfig',
  UpdateRouteTableConfig: 'UpdateRouteTableConfig',
  LoadRoute: 'LoadRoute',
  LoadRouteTableConfig: 'LoadRouteTableConfig',
  CreateRoute: 'CreateRoute',
  UpdateRoute: 'UpdateRoute',
  DeleteRoute: 'DeleteRoute',
  ChangeOrderStatusToCourier: 'ChangeOrderStatusToCourier',
})

const RouteMutationTypes = Object.freeze({
  SetRouteCollection: 'SetRouteCollection',
  SetRouteTableConfig: 'SetRouteTableConfig',
  UpdateRouteTableConfig: 'UpdateRouteTableConfig',
  SetRoute: 'SetRoute',
  UpdateRoute: 'UpdateRoute',
  RemoveRoute: 'RemoveRoute',
})

const RouteGetterTypes = Object.freeze({})

export { RouteNameSpace, RouteActionTypes, RouteMutationTypes, RouteGetterTypes }
