const DeliveryNameSpace = 'delivery'

const DeliveryActionTypes = Object.freeze({
  LoadDeliveryCollection: 'LoadDeliveryCollection',
  LoadDelivery: 'LoadDelivery',
  CreateDelivery: 'CreateDelivery',
  UpdateDelivery: 'UpdateDelivery',
  DeleteDelivery: 'DeleteDelivery',
  SetStatusToDelivery: 'SetStatusToDelivery',
})

const DeliveryMutationTypes = Object.freeze({
  SetDeliveryCollection: 'SetDeliveryCollection',
  SetDelivery: 'SetDelivery',
  UpdateDelivery: 'UpdateDelivery',
  RemoveDelivery: 'RemoveDelivery',
})


const DeliveryGetterTypes = Object.freeze({})

export { DeliveryNameSpace, DeliveryActionTypes, DeliveryMutationTypes, DeliveryGetterTypes }
