const MapNameSpace = 'map'

const MapActionTypes = Object.freeze({
  LoadMapOrdersCollection: 'LoadMapOrdersCollection',
  SetCourierToOrder: 'SetCourierToOrder',
  DeleteCourierFromOrder: 'DeleteCourierFromOrder',
  SetOrderToDelivery: 'SetOrderToDelivery',
})

const MapMutationTypes = Object.freeze({
  SetMapOrdersCollection: 'SetMapOrdersCollection',
})

const MapGetterTypes = Object.freeze({

})

export {
  MapNameSpace,
  MapActionTypes,
  MapMutationTypes,
  MapGetterTypes,
}
