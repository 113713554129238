const CostNameSpace = 'cost'

const CostActionTypes = Object.freeze({
  LoadCostCollection: 'LoadCostCollection',
  LoadCost: 'LoadCost',
  CreateCost: 'CreateCost',
  UpdateCost: 'UpdateCost',
  DeleteCost: 'DeleteCost',
})

const CostMutationTypes = Object.freeze({
  SetCostCollection: 'SetCostCollection',
  SetCost: 'SetCost',
  UpdateCost: 'UpdateCost',
  RemoveCost: 'RemoveCost',
})

const CostGetterTypes = Object.freeze({})

const ExpenseType = Object.freeze({
  1: 'variable',
  2: 'operating',
})

export { CostNameSpace, CostActionTypes, CostMutationTypes, CostGetterTypes, ExpenseType }
