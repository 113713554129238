import { CalendarMutationTypes } from './types'

export const mutations = {
  [CalendarMutationTypes.SetCalendarCollection](state, { orders, pageCount, date_from, date_to }) {
    state.orders = orders
    state.pageCount = pageCount
    state.date_from = new Date(date_from * 1000)
    state.date_to = new Date(date_to * 1000)
  },
}
