import { SourceActionTypes, SourceMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [SourceActionTypes.LoadSourceCollection](
      { commit },
      {
        page = 1,
        page_size = 10,
        search = '',
        status,
        sort_by = {
          sortBy: 'sort',
          sortDesc: 'asc',
        },
      },
  ) {
    try {
      const response = await this.$api.get_order_source_collection.fetch({
        params: {
          page,
          page_size,
          search,
          status,
          sort: `${sort_by.sortDesc === 'asc' ? '' : '-'}${sort_by.sortBy}`,
        },
      })

      if (response.data) {
        commit(SourceMutationTypes.SetSourceCollection, {
          sources: response.data.content,
          pageCount: response.data.pageCount,
          itemCount: response.data.total_count,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [SourceActionTypes.LoadSource]({ state, commit }, { id }) {
    const { sources } = state
    // TODO replace getter
    const source = sources.find(({ $id }) => $id === id)

    if (source) {
      commit(SourceMutationTypes.SetSource, { source })

      return
    }

    try {
      const response = await this.$api.get_order_source.get({ id })

      if (response.data) {
        commit(SourceMutationTypes.SetSource, { source: response.data })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [SourceActionTypes.CreateSource]({ commit }, { status, sort, color, name }) {
    try {
      await this.$api.create_order_source.post({
        body: {
          status,
          sort,
          color,
          name,
        },
      })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Источник добавлен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [SourceActionTypes.UpdateSource]({ state, commit }, { id, status, sort, color, name }) {
    const { sources } = state

    try {
      const source = await this.$api.update_order_source.post({
        id,
        body: {
          status,
          sort,
          color,
          name,
        },
      })

      if (sources.find(({ id: $id }) => $id === id)) {
        commit(SourceMutationTypes.UpdateSource, { source })
        commit(RootMutationTypes.SetErrorMessage, { message: 'Источник изменен', type: 'success' }, { root: true })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [SourceActionTypes.DeleteSource]({ commit }, { id }) {
    try {
      await this.$api.delete_order_source.post({ id })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Источник удален', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [SourceActionTypes.SetStatusToSource]({ commit }, { sources }) {
    const formData = new FormData()
    sources.forEach((item, i) => {
      formData.append('order-sources[' + i + '][status]', item.status)
      formData.append('order-sources[' + i + '][id]', item.id)
    })

    try {
      await this.$api.set_order_source_status.post({ body: formData })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Статус изменен', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
