import { AuthActionTypes, AuthMutationTypes } from './types'
import { RootMutationTypes } from '../../types'
import { sortBy } from 'lodash'

export const actions = {
  async [AuthActionTypes.UpdateUserData]({ commit }, { city_id }) {
    try {
      commit(AuthMutationTypes.SetUserData, { city_id })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },
  async [AuthActionTypes.LoadCityCollection]({ commit }, { page = 1, page_size = 100, status = 1 } = {}) {
    try {
      const response = await this.$api.get_city_collection.fetch({
        params: {
          page,
          page_size,
          status,
        },
      })

      if (response.data && response.data.content) {
        commit(AuthMutationTypes.SetCityCollection, {
          cities: sortBy(response.data.content, 'name'),
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },
}
