const AuthNameSpace = 'auth'

const RoleCollection = Object.freeze({
  admin: 'admin',
  courier: 'courier',
  florist: 'florist',
  manager: 'manager',
  root: 'root',
})

const AuthActionTypes = Object.freeze({
  UpdateUserData: 'UpdateUserData',
  LoadCityCollection: 'LoadCityCollection',
})

const AuthMutationTypes = Object.freeze({
  SetUserData: 'SetUserData',
  SetCityCollection: 'SetCityCollection',
})

const AuthGetterTypes = Object.freeze({

})


export { AuthNameSpace, RoleCollection, AuthActionTypes, AuthMutationTypes, AuthGetterTypes }
