const CityNameSpace = 'city'

const CityActionTypes = Object.freeze({
  LoadCityCollection: 'LoadCityCollection',
  LoadCityFullCollection: 'LoadCityFullCollection',
  LoadCity: 'LoadCity',
  CreateCity: 'CreateCity',
  UpdateCity: 'UpdateCity',
  DeleteCity: 'DeleteCity',
  SetStatusToCity: 'SetStatusToCity',
})

const CityMutationTypes = Object.freeze({
  SetCityCollection: 'SetCityCollection',
  SetCityFullCollection: 'SetCityFullCollection',
  SetCity: 'SetCity',
  UpdateCity: 'UpdateCity',
  RemoveCity: 'RemoveCity',
})

const CityGetterTypes = Object.freeze({

})

export {
  CityNameSpace,
  CityActionTypes,
  CityMutationTypes,
  CityGetterTypes,
}
