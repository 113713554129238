export default [
  {
    path: '/calendar-of-expenses',
    name: 'calendar-of-expenses',
    component: () =>
      import('@/views/pages/calendar-of-expenses/CalendarOfExpenses'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'Календарь расходов',
      permission: 'costsCalendarAccess',
    },
  },
]
