import Vue from 'vue'
import Vuex from 'vuex'
import { state } from './state'
import { mutations } from './mutations'

// Plugins
import api from '@/plugins/api'
// Plugins
// Vuexy modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// Vuexy modules

// custom modules
import { analytic } from './modules/analytic';
import { city } from './modules/city'
import { client } from './modules/client'
import { cost } from './modules/cost'
import { delivery } from './modules/delivery'
import { calendar } from './modules/calendar'
import { route } from './modules/route'
import { source } from './modules/source'
import { role } from './modules/role'
import { order } from './modules/order'
import { staff } from './modules/staff'
import { recipient } from './modules/recipient'
import { file } from './modules/file'
import { auth } from './modules/auth'
import { map } from './modules/map'
// custom modules

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [api],
  state,
  mutations,
  modules: {
    app,
    appConfig,
    verticalMenu,
    analytic,
    city,
    client,
    cost,
    delivery,
    calendar,
    route,
    source,
    role,
    order,
    staff,
    recipient,
    file,
    auth,
    map,
  },
  strict: process.env.DEV,
})

window.store = store

export default store
