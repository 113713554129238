import { AuthMutationTypes } from './types';

export const mutations = {
  [AuthMutationTypes.SetUserData](state, { city_id }) {
    state.city_id = city_id;
  },
  [AuthMutationTypes.SetCityCollection](state, { cities }) {
    state.cities = cities;
  },
}

