import { DeliveryMutationTypes } from './types'

export const mutations = {
  [DeliveryMutationTypes.SetDeliveryCollection](state, { deliveries, pageCount, itemCount }) {
    state.deliveries = deliveries
    state.pageCount = pageCount
    state.itemCount = itemCount
  },
  [DeliveryMutationTypes.SetDelivery](state, { delivery = {} }) {
    state.delivery = delivery
  },
  [DeliveryMutationTypes.UpdateDelivery](state, { delivery = {} }) {
    state.deliveries[delivery.id] = delivery
  },
}
