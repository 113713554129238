import { CityMutationTypes } from './types'

export const mutations = {
  [CityMutationTypes.SetCityCollection](state, { cities, pageCount, itemCount }) {
    state.cities = cities
    state.pageCount = pageCount
    state.itemCount = itemCount
  },
  [CityMutationTypes.SetCity](state, { city }) {
    state.city = city
  },
  [CityMutationTypes.UpdateCity](state, { city }) {
    state.cities[city.id] = city
  },
  [CityMutationTypes.SetCityFullCollection](state, { cities }) {
    state.allCities = cities
  },
}
