import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

// Access-Control-Allow-Origin:
const { jwt } = useJwt(axios, {
  // Endpoints
  loginEndpoint: process.env.VUE_APP_API_URL + '/auth/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
})

export default jwt
