import { OrderMutationTypes } from './types'
import Vue from 'vue'

export const mutations = {
  [OrderMutationTypes.SetOrderCollection](state, { orders, pageCount, itemCount, totalPrice, date_from, date_to }) {
    state.orders = orders
    state.pageCount = pageCount
    state.itemCount = itemCount
    state.totalPrice = totalPrice
    state.date_from = date_from ? new Date(date_from * 1000) : new Date()
    state.date_to = date_to ? new Date(date_to * 1000) : new Date()
  },
  [OrderMutationTypes.SetOrder](state, { order }) {
    state.order = order
  },
  [OrderMutationTypes.UpdateOrder](state, { order, index }) {
    Vue.set(state.orders, index, order)
  },
  [OrderMutationTypes.SetOrderTableConfig](state, { config }) {
    state.config = config
  },
  [OrderMutationTypes.UpdateOrderTableConfig](state, { config }) {
    state.config = config
  },

  [OrderMutationTypes.SetOrderProduct](state, { product }) {
    state.product = product
  },
  [OrderMutationTypes.UpdateOrderProduct](state, { product }) {
    state.products[product.id] = product
  },
  [OrderMutationTypes.DeleteOrderProduct](state, { id }) {
    for (let i = 0; i >= state.products.length; i++) {
      if (state.products[i].id === id) {
        state.products.splice(i, 1)
        break
      }
    }
  },
}
