import { ClientMutationTypes } from './types'

export const mutations = {
  [ClientMutationTypes.SetClientCollection](state, { clients, pageCount, itemCount, date_from, date_to }) {
    state.clients = clients
    state.pageCount = pageCount
    state.itemCount = itemCount
    state.date_from = new Date(date_from * 1000)
    state.date_to = new Date(date_to * 1000)
  },
  [ClientMutationTypes.SetClientTableConfig](state, { config }) {
    state.config = config
  },
  [ClientMutationTypes.UpdateClientTableConfig](state, { config }) {
    state.config = config
  },
  [ClientMutationTypes.SetClient](state, { client = {} }) {
    state.client = client
  },
  [ClientMutationTypes.UpdateClient](state, { client = {} }) {
    state.clients[client.id] = client
  },
}
