import { RouteMutationTypes } from './types'

export const mutations = {
  [RouteMutationTypes.SetRouteCollection](state, { routes, pageCount, itemCount }) {
    state.routes = routes
    state.pageCount = pageCount
    state.itemCount = itemCount
  },
  [RouteMutationTypes.SetRouteTableConfig](state, { config }) {
    state.config = config
  },
  [RouteMutationTypes.SetRouteTableConfig](state, { config }) {
    state.config = config
  },
  [RouteMutationTypes.UpdateRouteTableConfig](state, { config }) {
    state.config = config
  },
}
