export default [
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/pages/roles/Roles'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'Роли',
      permission: 'staffAccess',
    },
  },
]
