import { FileActionTypes, FileMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [FileActionTypes.LoadFile]({ commit }, { file }) {
    const formData = new FormData()
    formData.append('file', file)
    try {
      const { data } = await this.$api.load_file.postFile({
        body: formData,
      })

      commit(FileMutationTypes.SetFile, {
        file: data,
      })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
  async [FileActionTypes.LoadFiles]({ commit }, { form_data }) {
    try {
      const { data } = await this.$api.load_files.postFile({
        body: form_data,
      })

      commit(FileMutationTypes.SetFiles, {
        files: data,
      })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
