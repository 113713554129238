export default [
  {
    path: '/receivers',
    name: 'receivers',
    component: () => import('@/views/pages/receivers/Receivers'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'База получателей',
      permission: 'recipientBaseAccess',
    },
  },
  {
    path: '/receivers/:id',
    name: 'receivers-item',
    component: () => import('@/views/pages/receivers/ReceiverBaseItem'),
    meta: {
      redirectIfLoggedIn: false,
      title: 'Карточка получателя',
      permission: 'recipientBaseAccess',
    },
  },
]
