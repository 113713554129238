const StaffNameSpace = 'staff'

const StaffActionTypes = Object.freeze({
  LoadStaffCollection: 'LoadStaffCollection',
  LoadStaff: 'LoadStaff',
  CreateStaff: 'CreateStaff',
  UpdateStaff: 'UpdateStaff',
  DeleteStaff: 'DeleteStaff',
  UpdateCityStaff: 'UpdateCityStaff',
})

const StaffMutationTypes = Object.freeze({
  SetStaffCollection: 'SetStaffCollection',
  SetStaff: 'SetStaff',
  UpdateStaff: 'UpdateStaff',
  RemoveStaff: 'RemoveStaff',
})

const StaffGetterTypes = Object.freeze({})

export { StaffNameSpace, StaffActionTypes, StaffMutationTypes, StaffGetterTypes }
