export default [
  {
    path: '/base-of-staff',
    name: 'base-of-staff',
    component: () => import('@/views/pages/base-of-staff/BaseOfStaff'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'База сотрудников',
      permission: 'staffAccess',
    },
  },
  {
    path: '/base-of-staff/:id',
    name: 'staff-card',
    component: () => import('@/views/pages/base-of-staff/StuffCard'),
    meta: {
      redirectIfLoggedIn: false,
      title: 'Карточка сотрудника',
      permission: 'staffAccess',
    },
  },
  {
    path: '/base-of-staff/new',
    name: 'staff-card-new',
    component: () => import('@/views/pages/base-of-staff/StuffCard'),
    meta: {
      redirectIfLoggedIn: false,
      title: 'Карточка сотрудника',
      permission: 'staffAccess',
    },
  },
]
