const RootNameSpace = 'root'

const RootActionTypes = Object.freeze({
  SetErrorMessage: 'SetErrorMessage',
  SetLoadedState: 'SetLoadedState',
})

const RootMutationTypes = Object.freeze({
  SetErrorMessage: 'SetErrorMessage',
  SetLoadedState: 'SetLoadedState',
})

// TODO add error collection
// const ErrorMessage = Object.freeze({
//   Success: 'Success',
//   Error: 'Error',
// })

export { RootNameSpace, RootActionTypes, RootMutationTypes }
