import { state } from './state'
import { actions } from './actions'
import { mutations } from './mutations'

const auth = {
  namespaced: true,
  state,
  actions,
  mutations,
}

export { auth }
