import { CalendarActionTypes, CalendarMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [CalendarActionTypes.LoadCalendarCollection]({ commit, rootState }, { page = 1, page_size = 10, search = '', date_from = '', source_id, status, courier_id }) {
    try {
      const response = await this.$api.get_order_calendar_collection.fetch({
        params: {
          page,
          page_size,
          search,
          date_from,
          date_to: date_from,
          source_id,
          status,
          courier_id,
          city_id: rootState.auth.city_id,
        },
      })

      if (response.data) {
        commit(CalendarMutationTypes.SetCalendarCollection, {
          orders: response.data.content,
          pageCount: response.data.pageCount,
          ...response.data,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },
}
