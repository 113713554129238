export default [
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/pages/clients/Clients'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'База Клиентов',
      permission: 'clientsAccess',
    },
  },
  {
    path: '/clients/:id',
    name: 'client-edit',
    component: () => import('@/views/pages/clients/ClientCard'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'Карточка Клиента',
      permission: 'clientsAccess',
    },
  },
]
