import { AnalyticActionTypes, AnalyticMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [AnalyticActionTypes.LoadAnalyticCostDiagram]({ commit, rootState }, { date_from, date_to }) {
    try {
      const response = await this.$api.getCostsDiagram.fetch({
        params: {
          date_from,
          date_to,
          city_id: rootState.auth.city_id,
        },
      })

      commit(AnalyticMutationTypes.SetAnalyticCostDiagram, {
        diagram: response.data ? response.data : [],
      })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },
  async [AnalyticActionTypes.LoadAnalyticDeliveryDiagram]({ commit, rootState }, { date_from, date_to }) {
    try {
      const response = await this.$api.getDeliveryDiagram.fetch({
        params: {
          date_from,
          date_to,
          city_id: rootState.auth.city_id,
        },
      })

      commit(AnalyticMutationTypes.SetAnalyticDeliveryDiagram, {
        diagram: response.data ? response.data : [],
      })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },
  async [AnalyticActionTypes.LoadOrderDataSourceGraph]({ commit, rootState }, { date_from, date_to }) {
    try {
      const response = await this.$api.getOrderDataSourceGraph.fetch({
        params: {
          date_from,
          date_to,
          city_id: rootState.auth.city_id,
        },
      })

      commit(AnalyticMutationTypes.SetOrderDataSourceGraph, {
        graph: response.data ? response.data : [],
      })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
      throw message
    }
  },
}
