export const state = {
  orders: [],
  order: null,
  products: [],
  product: {},
  config: [],
  pageCount: 0,
  itemCount: 0,
  totalPrice: 0,
  date_from: '',
  date_to: '',
}
