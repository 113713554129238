export default [
  {
    path: '/routes',
    name: 'routes',
    component: () => import('@/views/pages/routes-page/RoutesPage'),
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: false,
      title: 'Маршрут для курьера',
      permission: 'routsAccess',
    },
  },
]
