import { ClientActionTypes, ClientMutationTypes } from './types'
import { RootMutationTypes } from '../../types'

export const actions = {
  async [ClientActionTypes.LoadClientCollection](
      { commit, rootState },
      {
        page = 1,
        page_size = 10,
        date_from = '',
        date_to = '',
        search = '',
        phone_search,
        sort_by = {
          sortBy: 'full_name',
          sortDesc: 'asc',
        },
        time = false,
      } = {},
  ) {
    try {
      const response = await this.$api.get_client_collection.fetch({
        params: {
          page,
          page_size,
          date_from,
          date_to,
          search,
          phone_search,
          city_id: rootState.auth.city_id,
          sort: `${sort_by.sortDesc === 'asc' ? '' : '-'}${sort_by.sortBy}`,
        },
      })

      // TODO update on back req for get time
      if (response.data) {
        if (time) {
          commit(ClientMutationTypes.SetClientCollection, {
            date_to: response.data.date_to,
            date_from: response.data.date_from,
          })
          return
        }
        commit(ClientMutationTypes.SetClientCollection, {
          clients: response.data.content,
          pageCount: response.data.pageCount,
          itemCount: response.data.total_count,
          ...response.data,
        })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [ClientActionTypes.LoadClient]({ state, commit }, { id }) {
    const { clients } = state
    // TODO replace getter
    const client = clients.find(({ $id }) => $id === id)

    if (client) {
      commit(ClientMutationTypes.SetClient, { client })

      return
    }

    try {
      const response = await this.$api.get_client.get({ id })

      if(response.data) {
        commit(ClientMutationTypes.SetClient, { client: response.data })
      }
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [ClientActionTypes.CreateClient]({ commit }, { city_id, full_name, phone }) {
    try {
      const { data } = await this.$api.create_client.post({
        body: {
          city_id,
          full_name,
          phone,
        },
      })
      commit(ClientMutationTypes.SetClient, { client: data })

      commit(RootMutationTypes.SetErrorMessage, { message: 'Заказчик создан', type: 'success' }, { root: true })
      return data
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [ClientActionTypes.UpdateClient]({ state, commit }, { id, full_name, phone }) {
    const { clients } = state

    try {
      const client = await this.$api.update_client.post({
        id,
        body: {
          full_name,
          phone,
        },
      })

      if (clients.find(({ id: $id }) => $id === id)) {
        commit(ClientMutationTypes.UpdateClient, { client })
      }
      commit(RootMutationTypes.SetErrorMessage, { message: 'Изменения сохранены', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [ClientActionTypes.DeleteClient]({ commit }, { id }) {
    try {
      await this.$api.delete_client.post({ id })
      commit(RootMutationTypes.SetErrorMessage, { message: 'Успешно', type: 'success' }, { root: true })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [ClientActionTypes.LoadClientTableConfig]({ commit }) {
    try {
      const config = await this.$api.get_client_table_config.fetch()

      commit(ClientMutationTypes.SetClientTableConfig, { config })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },

  async [ClientActionTypes.UpdateClientTableConfig]({ commit }, { settings }) {
    try {
      const formData = new FormData()
      settings.forEach((item, i) => {
        formData.append(`table_columns[${i}][name]`, item.name)
        formData.append(`table_columns[${i}][status]`, item.status)
      })

      const config = await this.$api.update_client_table_config.post({
        body: formData,
      })

      commit(ClientMutationTypes.SetClientTableConfig, { config })
    } catch (message) {
      commit(RootMutationTypes.SetErrorMessage, { message, type: 'error' }, { root: true })
    }
  },
}
