import { CostMutationTypes } from './types'

export const mutations = {
  [CostMutationTypes.SetCostCollection](state, { bills, pageCount, itemCount, date_from, date_to }) {
    state.bills = bills
    state.pageCount = pageCount
    state.itemCount = itemCount
    state.date_from = new Date(date_from * 1000)
    state.date_to = new Date(date_to * 1000)
  },
  [CostMutationTypes.SetCost](state, { cost = {} }) {
    state.cost = cost
  },
  [CostMutationTypes.UpdateCost](state, { cost = {} }) {
    state.bills[cost.id] = cost
  },
}
