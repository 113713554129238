import { SourceMutationTypes } from './types'

export const mutations = {
  [SourceMutationTypes.SetSourceCollection](state, { sources, pageCount, itemCount }) {
    state.sources = sources
    state.pageCount = pageCount
    state.itemCount = itemCount
  },
  [SourceMutationTypes.SetSource](state, { source = {} }) {
    state.source = source
  },
  [SourceMutationTypes.UpdateSource](state, { source = {} }) {
    state.sources[source.id] = source
  },
}
